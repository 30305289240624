import { Trans } from '@fec/assets/js/utils/trans';
import {
  MEDIA_ENDED,
  MEDIA_ERROR,
  MEDIA_PAUSED,
  MEDIA_PLAYING,
  onEvent,
} from '@fec/assets/js/utils/event';

const HOOK_SELECTOR = '.js-play-now-button';
const MEDIUM_SELECTOR = '.js-media, .js-player-widget';
const ARON_MEDIUM_CLASS = 'js-player-widget';
const ARON_CLICK_TARGET_SELECTOR = '.js-basic-video-caption';
const BUTTON_TEXT_SELECTOR = '.button-text';
const BUTTON_ICON_SELECTOR = '.button-icon';

/*
 * QUICK AND DIRTY implementation of the "Jetzt hören" button in radio episodes
 *
 * The button can only control the first medium in the <main> section of a page!
 */

export function init() {
  $(HOOK_SELECTOR).each((_, element) => new SrfPlayNowButton(element));
}

class SrfPlayNowButton {
  constructor(element) {
    this.$element = $(element);
    this.trans = new Trans();
    this.assetId = this.$element.attr('data-target-medium-id');
    this.$targetMedium = $(MEDIUM_SELECTOR, 'main').first();

    this.fixButtonWidth();
    this.registerListener();
    try {
      if (window.location.hash.includes('autoplay')) {
        if (
          navigator.userAgentData.brands.filter((brand) =>
            brand.brand.includes('Google Chrome'),
          ).length > 0
        ) {
          this.$element.click();
          window.location.replace('#played');
        }
      }
    } catch (e) {
      //lol
    }
  }

  fixButtonWidth() {
    // explicitly setting the initial width of the button because...
    // * the "pause" text is smaller than the play text
    // * the initial "play now" text width depends on the language
    // * the content of the play button must be centered
    this.$element.css('min-width', this.$element.outerWidth());
  }

  handleClick() {
    if (this.$targetMedium.hasClass(ARON_MEDIUM_CLASS)) {
      // ARON
      const clickTargetElement = this.$targetMedium.find(
        ARON_CLICK_TARGET_SELECTOR,
      );
      if (clickTargetElement.length > 0) {
        clickTargetElement.click();
      } else {
        const observer = new MutationObserver((mutations, obs) => {
          const newClickTargetElement = this.$targetMedium.find(
            ARON_CLICK_TARGET_SELECTOR,
          );
          if (newClickTargetElement.length > 0) {
            newClickTargetElement.click();
            obs.disconnect();
          }
        });
        observer.observe(this.$targetMedium[0], {
          childList: true,
          subtree: true,
        });
      }
    } else {
      // EZ CMS
      this.$targetMedium.click();
    }
  }

  registerListener() {
    this.$element.on('click', () => {
      this.setButtonText('loading');
      this.handleClick();
    });

    onEvent({
      eventName: MEDIA_PLAYING,
      eventHandler: ({ detail }) => {
        // aron: detail.urn, ez cms: detail.assetId
        if (detail.urn === this.assetId || detail.assetId === this.assetId) {
          this.setButtonText('pause');
          this.setButtonIcon('svg-icon--pause');
        }
      },
    });

    onEvent({
      eventName: [MEDIA_PAUSED, MEDIA_ENDED],
      eventHandler: ({ detail }) => {
        // aron: detail.urn, ez cms: detail.assetId
        if (detail.urn === this.assetId || detail.assetId === this.assetId) {
          this.setButtonText('playnow');
          this.setButtonIcon('svg-icon--play-audio');
        }
      },
    });

    onEvent({
      eventName: MEDIA_ERROR,
      eventHandler: ({ detail }) => {
        // aron: detail.urn, ez cms: detail.assetId
        if (detail.urn === this.assetId || detail.assetId === this.assetId) {
          this.setButtonText('error');
          this.$element.find(BUTTON_ICON_SELECTOR).remove();
          this.$element.off('click');
        }
      },
    });
  }

  setButtonText(textKey) {
    const buttonText = this.trans.accessTransOnElement(
      textKey,
      this.$element[0],
    );
    this.$element.find(BUTTON_TEXT_SELECTOR).text(buttonText);
  }

  setButtonIcon(iconClass) {
    this.$element
      .find(BUTTON_ICON_SELECTOR)
      .empty()
      .append('<span class="svg-icon ' + iconClass + '"></span>');
  }
}
